import DataFilterHelper from './DataFilterHelper';
import {
  FILTER_TYPE_CATEGORIES,
  FILTER_TYPE_DESTINATIONS,
  FILTER_TYPE_FOR_ENTHUSIAST,
  FILTER_TYPE_ORGANIZER,
  FILTER_TYPE_SORT,
  FILTER_TYPE_TIMES,
  FILTER_TYPE_TYPES,
} from '@/helpers/data-filter/DataFilterTypes';
import { isCurrentGeoPositionExist } from '@/helpers/GeolocationHelper';

class EventDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_DESTINATIONS]: [],
    [FILTER_TYPE_TYPES]: [],
    [FILTER_TYPE_CATEGORIES]: [],
    [FILTER_TYPE_TIMES]: [],
    [FILTER_TYPE_ORGANIZER]: null,
    [FILTER_TYPE_FOR_ENTHUSIAST]: null,
    [FILTER_TYPE_SORT]: null,
  };
  validValuesTypes = {
    array: [FILTER_TYPE_DESTINATIONS, FILTER_TYPE_TYPES, FILTER_TYPE_CATEGORIES, FILTER_TYPE_TIMES],
    boolean: [FILTER_TYPE_ORGANIZER, FILTER_TYPE_FOR_ENTHUSIAST],
  };

  /**
   * @param {Object} groupsWithItems
   * @param {Boolean} isEnthusiastMember
   * @param {FilterGroupItem[]|null} groupsWithItems.destinations
   * @param {FilterGroupItem[]|null} groupsWithItems.types
   * @param {FilterGroupItem[]|null} groupsWithItems.categories
   * @param {FilterGroupItem[]|null} groupsWithItems.times
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups(groupsWithItems, isEnthusiastMember = false) {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addDefaultGroupItems({
        group: groupsWithItems.destinations,
        itemsType: FILTER_TYPE_DESTINATIONS,
      }),
      this.addDefaultGroupItems({
        group: groupsWithItems.types,
        itemsType: FILTER_TYPE_TYPES,
      }),
      this.addDefaultGroupItems({
        group: groupsWithItems.categories,
        itemsType: FILTER_TYPE_CATEGORIES,
      }),
      this.addDefaultGroupItems({
        group: groupsWithItems.times,
        itemsType: FILTER_TYPE_TIMES,
      }),
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_ORGANIZER,
        items: [
          {
            title: 'Show Club Events',
            value: false,
          },
        ],
      }),
    ];

    if (isEnthusiastMember) {
      groups.push(
        this.addRadioGroupItems({
          itemsType: FILTER_TYPE_FOR_ENTHUSIAST,
          items: [
            {
              title: 'Show Enthusiast Events',
              value: true,
            },
          ],
        })
      );
    }

    if (isCurrentGeoPositionExist()) {
      groups.push(
        this.addRadioGroupItems({
          itemsType: FILTER_TYPE_SORT,
          items: [
            {
              title: 'Around me',
              value: { column: 'distance' },
            },
          ],
        })
      );
    }

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);

    return {
      filters: {
        destinationSlug: transformed[FILTER_TYPE_DESTINATIONS],
        type: transformed[FILTER_TYPE_TYPES],
        category: transformed[FILTER_TYPE_CATEGORIES],
        time: transformed[FILTER_TYPE_TIMES],
        organizer: transformed[FILTER_TYPE_ORGANIZER],
        forEnthusiast: transformed[FILTER_TYPE_FOR_ENTHUSIAST],
      },
      sort: transformed[FILTER_TYPE_SORT],
    };
  }
}

export default EventDataFilterHelper.create();
